import {Component, Input, OnInit} from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-form-input-icon-wrapper',
  templateUrl: './form-input-icon-wrapper.component.html',
  styleUrls: ['./form-input-icon-wrapper.component.scss']
})
export class FormInputIconWrapperComponent implements OnInit {

  @Input() iconRightSide: boolean = false;
  @Input() iconClass = '';

  constructor() {
  }

  ngOnInit(): void {
  }

}
