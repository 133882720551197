import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from "./pages/login/login.component";
import { CheckboxModule } from "primeng/checkbox";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppConfigModule } from "../../layout/config/app.config.module";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { InputTextModule } from "primeng/inputtext";
import { MessageModule } from "primeng/message";
import {
  FormInputIconWrapperComponent
} from "../../shared/components/form-input-icon-wrapper/form-input-icon-wrapper.component";
import { FormErrorComponent } from "../../shared/components/form-error/form-error.component";
import { LoginWithOnboardingComponent } from './components/login-with-onboarding/login-with-onboarding.component';
import { GoogleSigninButtonModule } from "@abacritt/angularx-social-login";
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { ProgressSpinnerModule } from "primeng/progressspinner";


@NgModule({
    declarations: [
        LoginComponent,
        LoginWithOnboardingComponent,
        LoginDialogComponent
    ],
    exports: [
        LoginWithOnboardingComponent
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        CheckboxModule,
        FormsModule,
        AppConfigModule,
        ButtonModule,
        RippleModule,
        InputTextModule,
        MessageModule,
        ReactiveFormsModule,
        FormInputIconWrapperComponent,
        FormErrorComponent,
        GoogleSigninButtonModule,
        ProgressSpinnerModule
    ]
})
export class AuthModule { }
