import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'backoffice-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  errorMessage: string | undefined;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
  ) {}

  get dark(): boolean {
    return false;
  }

  loginForm = this.fb.group({
    email: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    password: ['', [Validators.required, Validators.maxLength(255)]],
    rememberMe: [false],
  });

  login() {
    this.isLoading = true;
    this.errorMessage = undefined;
    const payload = this.loginForm.value;

    // wait for 2 seconds to simulate API call
    setTimeout(() => {
      this.isLoading = false;
      this.errorMessage = 'Invalid email or password';
    }, 800);
  }

  ngOnInit(): void {

  }
}

